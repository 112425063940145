import { initializeApp } from 'firebase/app';
import {
    getAuth,
    onAuthStateChanged,
    signOut,
    signInWithEmailAndPassword,
    NextOrObserver,
    User
} from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyDWvI80NZP-jUM9A6ygE3thxvqMviTxEJg",
    authDomain: "ssatelital-signal.firebaseapp.com",
    databaseURL: "https://ssatelital-signal.firebaseio.com",
    projectId: "ssatelital-signal",
    storageBucket: "ssatelital-signal.appspot.com",
    messagingSenderId: "574337082636",
    appId: "1:574337082636:web:38d6c6de313da6c7"
  };

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export const signInUser = async (
    email: string,
    password: string
) => {
    if (!email && !password) return;

    return await signInWithEmailAndPassword(auth, email, password)
}

export const userStateListener = (callback: NextOrObserver<User>) => {
    return onAuthStateChanged(auth, callback)
}

export const SignOutUser = async () => await signOut(auth);
