
import { Backdrop, CircularProgress, Stack } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import SurveyMap from '../components/map'
import Sidebar, { SelectedFilter } from '../components/sidebar'
import SurveyTable from '../components/table'
import { DataContext, Survey } from '../context/dataContext'

function Main() {
    const { query, data, loading, techs } = useContext(DataContext);
    const [showTable, setShowTable] = useState(false)
    const [filteredData, setFilteredData] = useState<Survey[]>([])
    const [filterTechs, setTechs] = useState<string[]>([])

    const onSelected = (selected: SelectedFilter) => {
        console.log("Selected", selected)
        query(selected);
    }

    useEffect(() => {
        const hasTech = (s: Survey) => {
            return s.cells.filter(c => filterTechs.indexOf(c.type) > -1).length > 0
        }
        setFilteredData(data.filter(s => hasTech(s)))
        /*
        if (filterTechs.length === 0 || techs.length === filterTechs.length) {
            setFilteredData(data)
        } else {
            setFilteredData(data.filter(s => hasTech(s)))
        }
        */
    }, [data, filterTechs, techs])

    return <>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}

        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Stack direction="row" flex={1} height={"100%"} style={{ backgroundColor: "#d0d0d0" }}>
            <div style={{ width: "350px", position: "absolute", zIndex: 1000, padding: "1rem" }}>
                <Sidebar
                    onSelect={onSelected}
                    onTechChange={(sel) => setTechs(sel)}
                    onViewChange={(table) => setShowTable(table)} />
            </div>
            {!showTable && <SurveyMap data={filteredData} />}
            {showTable &&
                <>
                    <div style={{ width: "350px" }}></div>
                    <div style={{ flex: 1, margin: "1rem", marginLeft: 0, backgroundColor: "#fff" }}>
                        <SurveyTable data={data} />
                    </div>
                </>
            }
        </Stack>
    </>
}
export default Main
