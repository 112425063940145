import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { CellInfoGsmSchema, CellInfoLteSchema, CellInfoUtmsSchema } from "../context/dataContext";

interface Props {
    data: Array<{ id?: number } & (CellInfoGsmSchema | CellInfoLteSchema | CellInfoUtmsSchema)>,
}


const CellsDataGrid = ({ data }: Props) => {
    const cols: GridColDef<(typeof data)[number]>[] = [
        { field: "id", headerName: "ID"},
        { field: "type", headerName: "Tech" },
        { field: "registered", headerName: "Serving" },
        { field: "mncmcc", headerName: "MCC/MNC", valueGetter: (v, row) => `${row.mcc}/${row.mnc}` },
        { field: "dbm", headerName: "Power" },
    ]

    data.forEach((v, i) => v.id = i)
    return <DataGrid
        columns={cols} rows={data}
    />
}

export default CellsDataGrid