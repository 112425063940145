import { Button, Card, CardContent, Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent, Stack, Switch, Typography } from "@mui/material";
import { Dayjs } from "dayjs";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/authContext";
import { DataContext } from "../context/dataContext";

export interface SelectedFilter {
    users: string[],
    mncs: string[],
    surveys: string[],
    desde: Dayjs | null,
    hasta: Dayjs | null,
}

interface Props {
    onSelect?: (selected: SelectedFilter) => void,
    onTechChange?: (techs: string[]) => void,
    onViewChange?: (table: boolean) => void,
}


const Sidebar = ({ onSelect, onViewChange, onTechChange }: Props) => {
    const { signOut } = useContext(AuthContext)
    const [selectedUsers, setUser] = useState<string[]>([])
    const [selectedMNCs, setMNC] = useState<string[]>([])
    const [selectedSurveys, setSurvey] = useState<string[]>([
        "AR MZ RN 040",
        "AR MZ RN 04O",
        "AR MZ RN 142",
        "AR MZ RN 143",
        "AR MZ RN 144",
        "AR MZ RN 145",
        "AR MZ RN 146",
        "AR MZ RN 149",
        "AR MZ RN 188",
        "AR MZ RP 014",
        "AR MZ RP 015",
        "AR MZ RP 016",
        "AR MZ RP 024",
        "AR MZ RP 027",
        "AR MZ RP 034",
        "AR MZ RP 041",
        "AR MZ RP 050",
        "AR MZ RP 052",
        "AR MZ RP 060",
        "AR MZ RP 061",
        "AR MZ RP 062",
        "AR MZ RP 063",
        "AR MZ RP 071",
        "AR MZ RP 077",
        "AR MZ RP 082",
        "AR MZ RP 086",
        "AR MZ RP 088",
        "AR MZ RP 089",
        "AR MZ RP 092",
        "AR MZ RP 094",
        "AR MZ RP 096",
        "AR MZ RP 101",
        "AR MZ RP 150",
        "AR MZ RP 153",
        "AR MZ RP 171",
        "AR MZ RP 173",
        "AR MZ RP 179",
        "AR MZ RP 180",
        "AR MZ RP 186",
        "AR MZ RP 186 ",
        "AR MZ RP 190",
        "AR MZ RP 213",
        "AR MZ RP 222",
        "AR MZ RP 222  "
    ])
    
    const { loading, mncs, surveys, users, techs } = useContext(DataContext)
    const [selectedTechs, setTech] = useState<string[]>(techs)
    const [selectedView, setSelectedView] = useState(false);

    const handleViewChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onViewChange && onViewChange(event.target.checked);
        setSelectedView(event.target.checked);
    };

    const handleTechChange = (ev: SelectChangeEvent<string[]>) => {
        var sel = ev.target.value as string[]
        if (sel.indexOf("all") > -1) {
            sel = techs;
        }
        setTech(sel)
        onTechChange && onTechChange(sel)
    }

    useEffect( () => {
        onTechChange && onTechChange(selectedTechs)
    }, [])

    return <>
        <Stack spacing={2}>
            <Card variant="elevation">
                <CardContent>
                    <Stack spacing={2}>
                        { /* 
                        <FormControl disabled={loading} fullWidth>
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent={"center"}>
                                <Typography>Mapa</Typography>
                                <Switch checked={selectedView} onChange={handleViewChange} />
                                <Typography>Tabla</Typography>
                            </Stack>
                        </FormControl>
                        */ }
                        {!selectedView && <FormControl disabled={loading} fullWidth>
                            <InputLabel id="techs-label">Tech</InputLabel>
                            <Select
                                labelId="techs-label"
                                value={selectedTechs}
                                label="Tech"
                                multiple
                                renderValue={(selected) => selected.join(', ')}
                                onChange={handleTechChange}
                            >
                                <MenuItem key={"all"} value={"all"}>
                                    <Checkbox checked={selectedTechs.length === techs.length} />
                                    <ListItemText primary="Todas" />
                                </MenuItem>
                                {techs.map(u => (
                                    <MenuItem key={u} value={u}>
                                        <Checkbox checked={selectedTechs.indexOf(u) > -1} />
                                        <ListItemText primary={u} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        }
                    </Stack>

                </CardContent>
            </Card>

            <Card variant="elevation">
                <CardContent>
                    <Stack spacing={2}>
                        <FormControl disabled={loading} fullWidth>
                            <InputLabel id="surveys-label">Relevamiento</InputLabel>
                            <Select
                                labelId="surveys-label"
                                value={selectedSurveys}
                                label="Relevamientos"
                                multiple
                                renderValue={(selected) => selected.join(', ')}
                                onChange={(v) => setSurvey(v.target.value as string[])}
                            >
                                {surveys.map(u => <MenuItem key={u} value={u}>
                                    <Checkbox checked={selectedSurveys.indexOf(u) > -1} />
                                    <ListItemText primary={u} />
                                </MenuItem>)}
                            </Select>
                        </FormControl>
                        <FormControl disabled={loading} fullWidth>
                            <InputLabel id="users-label">Usuario</InputLabel>
                            <Select
                                labelId="users-label"
                                value={selectedUsers}
                                label="Usuario"
                                multiple
                                renderValue={(selected) => selected.join(', ')}
                                onChange={(v) => setUser(v.target.value as string[])}
                            >
                                {users.map(u => <MenuItem key={u} value={u}>
                                    <Checkbox checked={selectedUsers.indexOf(u) > -1} />
                                    <ListItemText primary={u} />
                                </MenuItem>)}
                            </Select>
                        </FormControl>

                        <FormControl disabled={loading} fullWidth>
                            <InputLabel id="mncs-label">MNC</InputLabel>
                            <Select
                                labelId="mncs-label"
                                value={selectedMNCs}
                                label="MNC"
                                multiple
                                renderValue={(selected) => selected.join(', ')}
                                onChange={(v) => setMNC(v.target.value as string[])}
                            >
                                {mncs.map(u => <MenuItem key={u} value={u}>
                                    <Checkbox checked={selectedMNCs.indexOf(u) > -1} />
                                    <ListItemText primary={u} />
                                </MenuItem>)}
                            </Select>
                        </FormControl>



                        <Button variant="contained" color="primary" onClick={() => {
                            if (onSelect) {
                                onSelect({
                                    desde: null,
                                    hasta: null,
                                    mncs: selectedMNCs,
                                    surveys: selectedSurveys,
                                    users: selectedUsers
                                })
                            }
                        }}>Filtrar</Button>
                    </Stack>
                </CardContent >
            </Card >

            <Card variant="elevation">
                <CardContent>
                    <Stack spacing={2}>
                        <Button onClick={signOut}>Cerrar Sesión</Button>
                    </Stack >
                </CardContent >
            </Card >
        </Stack>
    </>
}

export default Sidebar;