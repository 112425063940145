import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useContext, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.css';
import RequireAuth from './components/requireAuth';
import { AuthContext } from './context/authContext';
import Home from './routes/home';
import Main from './routes/main';
import { APIProvider } from '@vis.gl/react-google-maps';

function App() {
  const { currentUser } = useContext(AuthContext)
  const navigate = useNavigate()

  // NOTE: console log for testing purposes
  console.log('User:', !!currentUser);

  // Check if the current user exists on the initial render.
  useEffect(() => {
    if (currentUser) {
      navigate('/main')
    }
  }, [currentUser, navigate])

  return (
    <div className='App'>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <APIProvider apiKey={"AIzaSyCs7tMvOZ-6433d9R2L0qMotHgUlx2_5IA"}>

          <Routes>
            <Route index element={<Home />} />

            <Route path="main" element={
              <RequireAuth>
                <Main />
              </RequireAuth>
            } />
          </Routes>
        </APIProvider>
      </LocalizationProvider>
    </div>
  )
}

export default App;
