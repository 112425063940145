import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { Map, useMap } from "@vis.gl/react-google-maps";
import { GeoJsonLayer, LayersList, PickingInfo } from "deck.gl";
import { useEffect, useMemo, useState } from "react";
import { getQualityColor, Survey } from "../context/dataContext";
import type { Feature, GeoJSON, Point, Geometry, GeoJsonProperties } from 'geojson';
import { DeckGlOverlay } from "./overlay";
import { GoogleMapsOverlay } from "@deck.gl/google-maps";
import SurveyListPopup from "./surveyPopup";

interface Props {
    data: Array<Survey>
}

const SurveyMap = ({ data }: Props) => {
    const [layers, setLayers] = useState<LayersList>([])
    const map = useMap();
    const [selected, setSelected] = useState<Array<Survey | undefined>>([])
    
    // Si no hago esto, pincha!
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const clusterer = useMemo(() => {
        if (!map) return null;

        return new MarkerClusterer({ map });
    }, [map]);
    
    // the GoogleMapsOverlay can persist throughout the lifetime of the DeckGlOverlay
    const deck = useMemo(() => new GoogleMapsOverlay({
        interleaved: true,
    }), []);


    useEffect(() => {
        if (data.length === 0) {
            setLayers([])
            return
        }
        var b = new google.maps.LatLngBounds()

        var points: Array<Feature<Geometry, GeoJsonProperties>> = []

        data.forEach(s => {
            b.extend(new google.maps.LatLng(s.location.coordinates[1], s.location.coordinates[0]))
            points.push({
                type: "Feature",
                properties: {
                    // count: count(s)
                    color: getQualityColor(s),
                    id: s.id,
                },
                geometry: s.location as Point
            })
        })

        var layer: GeoJSON | undefined = {
            type: "FeatureCollection",
            features: points
        }

        var l = new GeoJsonLayer({
            id: "test",
            data: layer,
            stroked: true,
            filled: true,
            extruded: false,
            pointType: 'circle',
            pickable: true,
            // lineWidthMinPixels: 0,
            // getFillColor: [16, 16, 18, 100],
            onClick: ((i, e) => {
                console.log("Click", i.object)
                // Query up to 5 overlapping objects under the pointer
                const pickInfos: PickingInfo[] = deck.pickMultipleObjects({ x: i.x, y: i.y, radius: 50, depth: 10 }) as PickingInfo[]
                const s = pickInfos.map((p) => data.find(s => s.id === p.object.properties.id))

                setSelected(s);
            }),
            getFillColor: (f: Feature) => {
                const hex = f?.properties?.color;

                if (!hex) return [0, 0, 0, 220];
                const [a, b, c] = hex.match(/[0-9a-f]{2}/g)!.map((x: string) => parseInt(x, 16));
                return [a, b, c, 200] // hex.match(/[0-9a-f]{2}/g)!.map((x: string) => parseInt(x, 16));
            },
            getPointRadius: 200,
            pointRadiusMinPixels: 6,
            getLineWidth: 5,
            getElevation: 30
        });
        setLayers([l])
        console.log("Bounds", b)
        
        map?.fitBounds(b, 10);
    }, [data, map, deck])

    return <>
        <Map
            style={{ width: '100vw', height: '100vh' }}
            defaultCenter={{ lat: 0, lng: 0 }}
            defaultZoom={3}
            gestureHandling={'greedy'}
            mapId={"98004e6d6e654b7c"}
            disableDefaultUI={true}
            reuseMaps={true}
            maxZoom={14}
            clickableIcons={false}
        >

            <DeckGlOverlay deck={deck} layers={layers} />

        </Map>
        
        <SurveyListPopup data={selected} onClose={() => setSelected([])} />
    </>
}

export default SurveyMap