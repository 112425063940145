import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { AuthContext } from "./authContext";
import { SelectedFilter } from "../components/sidebar";

interface Props {
    children?: ReactNode
}

interface Location {
    type: String,
    coordinates: number[]
}
interface CosaLoca {
    provided: "integer";
    integer: string;
}


export enum Quality {
    EXCELLENT,
    GOOD,
    ACCEPTABLE,
    BAD,
    NO_SERVICE
}

export interface CellInfoUtmsSchema {
    type: "wcdma"
    cellId: number
    dbm: number
    downlink: number | CosaLoca
    uarfcn: number
    mcc: number
    mnc: number
    lac: number
    registered: boolean
}

export interface CellInfoGsmSchema {
    type: "gsm"
    cellId: number
    arfcn: number
    band: string
    mcc: number
    mnc: number
    lac: number
    uplink: number | CosaLoca
    downlink: number | CosaLoca
    asu: number
    dbm: number
    level: number
    registered: boolean
}

export interface CellInfoLteSchema {
    type: "lte"
    cellId: number
    dbm: number
    downlink: number | CosaLoca
    earfcn: number
    mcc: number
    mnc: number
    physCellId: number
    rsrp: number
    rsrq: number
    tac: number
    uplink: number | CosaLoca
    registered: boolean
}

export interface Survey {
    id: string,
    name: string,
    user: string,
    timestamp: string,
    deleted: boolean,
    operator: string,
    location: Location,
    cells: Array<CellInfoGsmSchema | CellInfoLteSchema | CellInfoUtmsSchema>,
}

export const DataContext = createContext({
    // "User" comes from firebase auth-public.d.ts
    users: [] as string[],
    surveys: [] as string[],
    mncs: [] as string[],
    techs: [] as string[],
    refresh: () => { },
    query: (selected: SelectedFilter) => { },
    loading: false,
    data: [] as Survey[],
});


export const getQuality = (t: CellInfoGsmSchema | CellInfoLteSchema | CellInfoUtmsSchema) => {
    // return 'lte' === t.type ? t.rsrq : 'gsm' === t.type ? t.dbm - 40 : 'wcdma' === t.type ? t.dbm : - 1
    return t ? 'lte' === t.type ? t.rsrp >= - 90 ? Quality.EXCELLENT : t.rsrp >= - 105 ? Quality.GOOD : t.rsrp >= - 110 ? Quality.ACCEPTABLE : t.rsrp >= - 119 ? Quality.BAD : Quality.NO_SERVICE : 'gsm' === t.type ? t.dbm >= - 75 ? Quality.EXCELLENT : t.dbm >= - 90 ? Quality.GOOD : t.dbm >= - 100 ? Quality.ACCEPTABLE : t.dbm >= - 120 ? Quality.BAD : Quality.NO_SERVICE : 'wcdma' === t.type ? t.dbm >= - 70 ? Quality.EXCELLENT : t.dbm >= - 85 ? Quality.GOOD : t.dbm >= - 100 ? Quality.ACCEPTABLE : t.dbm >= - 109 ? Quality.BAD : Quality.NO_SERVICE : void 0 : Quality.NO_SERVICE
}

export const getRegisteredCell = (t: Survey) => {
    return t.cells.find(c => c.registered)
}

export const getRegisteredQuality = (s: Survey) => {
    const cell = getRegisteredCell(s);
    if (cell) {
        return getQuality(cell);
    } else {
        return Quality.NO_SERVICE
    }
}

export const getQualityColor = (s: Survey) => {
    switch (getRegisteredQuality(s)) {
        case Quality.EXCELLENT:
            return '#00c853';
        case Quality.GOOD:
            return '#b2ff59';
        case Quality.ACCEPTABLE:
            // return '#f4ff81';
            return '#d50000';
        case Quality.BAD:
            return '#d50000';
        case Quality.NO_SERVICE:
            return '#000000'
    }
}

export const DataProvider = ({ children }: Props) => {
    const [loading, setLoading] = useState(false)
    const { currentUser } = useContext(AuthContext);

    const [users, setUsers] = useState<string[]>([])
    const [surveys, setSurveys] = useState<string[]>([])
    const [mncs, setMNCs] = useState<string[]>([])

    const [data, setData] = useState<Survey[]>([])

    const refresh = useCallback(async () => {
        if (!currentUser) {
            return
        }
        setLoading(true);

        const u = fetch("https://signal.bellagamba.com.ar/api/users", {
            method: 'post',
            headers: {
                authorization: `Bearer ${await currentUser?.getIdToken()}`
            }
        })
        const s = fetch("https://signal.bellagamba.com.ar/api/surveys", {
            method: 'post',
            headers: {
                authorization: `Bearer ${await currentUser?.getIdToken()}`
            }
        })
        const m = fetch("https://signal.bellagamba.com.ar/api/mncs", {
            method: 'post',
            headers: {
                authoriuserzation: `Bearer ${await currentUser?.getIdToken()}`
            }
        })
        let res = await Promise.all([u, s, m])
        const userJson = await res[0].json()
        setUsers(userJson.res)
        const sJson = await res[1].json()
        setSurveys(sJson.res)
        const mJson = await res[2].json()
        setMNCs(mJson.res)
        setLoading(false);
    }, [currentUser])

    useEffect(() => {
        console.log("Refreshing")
        refresh()
    }, [refresh])

    const query = async (selected: SelectedFilter) => {
        setLoading(true);

        const res = await fetch("https://signal.bellagamba.com.ar/api/query", {
            method: 'post',
            headers: {
                authorization: `Bearer ${await currentUser?.getIdToken()}`,
                'Content-Type': 'application/json'
            },
            mode: 'cors',

            body: JSON.stringify({
                // from: selected.desde,
                // to: selected.hasta,
                mncMccs: selected.mncs,
                users: selected.users,
                name: selected.surveys,
                groupByDistance: 0,
                limit: 100
            })
        })

        const body = await res.json()
        const data = body.res as Survey[]
        console.log("Body", data)
        setData(data);
        setLoading(false)
    }

    const value = {
        mncs,
        users,
        refresh,
        surveys,
        loading,
        techs: ['wcdma', /* 'gsm', */ 'lte'],
        query,
        data
    }

    return <DataContext.Provider value={value}>{children}</DataContext.Provider>
}
