import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, DialogActions, DialogContent, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Survey } from "../context/dataContext";
import CellsDataGrid from "./cellsDataGrid";
interface Props {
    data: Array<Survey | undefined>,
    onClose: () => void
}

const SurveyListPopup = ({ data, onClose }: Props) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (data && data.length > 0) {
            setOpen(true)
            console.log(data)
        } else {
            setOpen(false)
        }
    }, [data])
    return <Dialog
        open={open}
        onClose={onClose}

        fullWidth
    >
        <DialogContent>
            {data.map((s, i) => <Accordion key={i} defaultExpanded={i === 0}>
                <AccordionSummary>
                    <Typography flexGrow={1}>{s?.user}</Typography>
                    <Typography sx={{ color: 'text.secondary' }}>{s?.timestamp}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {s && <CellsDataGrid data={s.cells} />}
                </AccordionDetails>
            </Accordion>)}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>Cerrar</Button>
        </DialogActions>

    </Dialog>
}

export default SurveyListPopup;