import { DataGrid, GridColDef, GridColumnVisibilityModel, GridSortModel, GridToolbarContainer, GridToolbarExport, useGridApiRef } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { Survey } from "../context/dataContext";

interface Props {
    data: Survey[]
}

interface Row {
    id: string
    user: string
    timestamp: string,
    tech: string
    registered: boolean,
    mnc: number,
    mcc: number,
    power: number,
    name: string,
    lat: number,
    lon: number
}

const SurveyTable = ({ data }: Props) => {
    const [rows, setRows] = useState<Row[]>([])
    const apiRef = useGridApiRef();
    const cols: GridColDef<(Row[])[number]>[] = [
        { field: "id", headerName: "ID" },
        { field: "name", headerName: "Survey" },
        { field: "timestamp", headerName: "Fecha" },
        { field: "user", headerName: "Usuario" },
        { field: "tech", headerName: "Tech" },
        { field: "registered", headerName: "Registered" },
        { field: "power", headerName: "Dbm" },
        { field: "mcc", headerName: "mcc" },
        { field: "mnc", headerName: "mnc" },
        { field: "lat", headerName: "Latitud" },
        { field: "lon", headerName: "Longitud" },
    ]
    const [sortModel, setSortModel] = useState<GridSortModel>([
        {
            field: 'timestamp',
            sort: 'asc',
        },
    ]);

    const [visibilityModel, setVisibilityModel] = useState<GridColumnVisibilityModel>(
        {
            id: false,
        },
    );

    function CustomToolbar() {
        return (
            <GridToolbarContainer>
                <GridToolbarExport />
            </GridToolbarContainer>
        );
    }


    useEffect(() => {
        var rows: Row[] = []
        data.forEach(s => {
            s.cells.forEach((c, i) => {
                rows.push({
                    id: `${s.id}-${i}`,
                    user: s.user,
                    timestamp: s.timestamp,
                    tech: c.type,
                    registered: c.registered,
                    mnc: c.mnc,
                    mcc: c.mcc,
                    power: c.dbm,
                    name: s.name,
                    lat: s.location.coordinates[1],
                    lon: s.location.coordinates[0]
                })
            })
        })
        setRows(rows);

        setTimeout(() => apiRef.current.autosizeColumns({ expand: true }), 100);
    }, [data, apiRef])

    return <DataGrid
        columns={cols} rows={rows}
        apiRef={apiRef}
        autosizeOnMount={true}
        sortModel={sortModel}
        onSortModelChange={setSortModel}
        columnVisibilityModel={visibilityModel}
        onColumnVisibilityModelChange={setVisibilityModel}
        
        autosizeOptions={{
            expand: true,
        }}
        slots={{
            toolbar: CustomToolbar
        }}
    />
}

export default SurveyTable