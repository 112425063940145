import { ChangeEvent, FormEvent, useState } from 'react'
import { signInUser } from '../firebase/firebase'
import { useNavigate } from 'react-router-dom'
import '.././App.css'
import { Button, Stack, TextField } from '@mui/material'

const defaultFormFields = {
    email: '',
    password: '',
}

function Home() {
    const [formFields, setFormFields] = useState(defaultFormFields)
    const { email, password } = formFields
    const navigate = useNavigate()

    const resetFormFields = () => {
        return (
            setFormFields(defaultFormFields)
        );
    }

    const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        try {
            // Send the email and password to firebase
            const userCredential = await signInUser(email, password)

            if (userCredential) {
                resetFormFields()
                navigate('/main')
            }
        } catch (error: any) {
            console.log('User Sign In Failed', error.message);
        }
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target
        setFormFields({ ...formFields, [name]: value })
    }

    return (
        <form onSubmit={handleSubmit}>
            <Stack spacing={2}
                justifyContent="center"
                alignItems="center">
                <div>
                    <TextField type='email' name='email' label="Email"
                        onChange={handleChange}
                        required
                        variant='outlined' />
                </div>
                <div>
                    <TextField
                        type='password'
                        name='password'
                        value={password}
                        onChange={handleChange}
                        label="Password"
                        required
                    />
                </div>
                <div>
                    <Button id='recaptcha' type="submit">Ingresar</Button>
                </div>
            </Stack>
        </form>

    )
}

export default Home
